<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <async-object
        should-fetch
        :fetch-method="fetchData"
      >
        <div class="heading d-flex justify-content-between mb-5">
          <h2>Historique des messages</h2>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <router-link class="btn btn-link btn-sm" :to="{ name: 'customerMessages.index' }" active-class="active">
                <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
              </router-link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-lg-9 col-xl-9">
            <div class="card mb-4">
              <div class="card-body">
                <dl class="row">
                  <dt class="col-4">Client</dt>
                  <dd class="col-8">{{ customerName }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Commande</dt>
                  <dd class="col-8">
                    <router-link :to="`/orders/${this.merchantOrderId}`">{{ orderReference }}</router-link>
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Dernier message</dt>
                  <dd class="col-8">{{ lastMessage ? $longDateTime(lastMessage.createdAt) : '-' }}</dd>
                </dl>
              </div>
            </div>

            <h3 class="subtitle my-4">Messages</h3>

            <div class="mb-4">
              <message-composer @submit="sendMessage" />
            </div>

            <div class="card mb-4" v-for="message in orderedMessages" :key="message.id">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="author">
                    <strong>{{ getMessageAuthor(message) }}</strong>
                  </div>
                  <div class="date">
                    <small>{{ getMessageDate(message) }}</small>
                  </div>
                </div>

                <div class="message">{{ message.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import { get, orderBy } from 'lodash-es'
import MessageComposer from '@/components/common/MessageComposer'
import userData from '@/mixins/user-data'
import formatsDate from '@/mixins/formatsDate'
import http from '@/utils/http'

export default {
  mixins: [userData, formatsDate],
  components: {
    MessageComposer
  },
  data () {
    return {
      summary: {}
    }
  },
  computed: {
    orderedMessages () {
      return orderBy(this.$store.getters['customerMessages/history/getList'](this.$route.params.orderId), ['createdAt'], ['desc'])
    },
    lastMessage () {
      return this.orderedMessages.length ? this.orderedMessages[0] : null
    },
    customerName () {
      if (this.summary) {
        return `${get(this.summary, 'order.customer.firstName', '')} ${get(this.summary, 'order.customer.lastName', '')}`
      }

      return ''
    },
    orderReference () {
      return get(this.summary, 'order.reference', '')
    },
    merchantOrderId () {
      return get(this.summary, 'id', '')
    }
  },
  methods: {
    async fetchData () {
      await this.fetchMessages()

      const { data: summary } = await http.get(`/v1/stores/${this.currentStoreId}/merchant-orders/order/${this.$route.params.orderId}`)
      this.summary = summary

      this.markAsRead()
    },
    async fetchMessages () {
      await this.$store.dispatch('customerMessages/history/fetchOrRefresh', {
        parentId: this.$route.params.orderId,
        storeId: this.currentStoreId
      })
    },
    getMessageAuthor (message) {
      if (message.authorType === 'merchant') {
        return 'Vendeur'
      }

      return `${get(message, 'author.firstName')} ${get(message, 'author.lastName')}`
    },
    getMessageDate (message) {
      return this.$shortDateTime(message.createdAt)
    },
    markAsRead () {
      http.patch(`/v1/customer-messages/orders/${this.$route.params.orderId}/stores/${this.currentStoreId}/read-status`)
    },
    async sendMessage (message, onSuccess, onError) {
      try {
        await http.post('/v1/customer-messages', {
          text: message,
          orderId: this.$route.params.orderId,
          storeId: this.currentStoreId
        })

        this.fetchMessages()
        onSuccess(false)

        this.$toasted.success('Votre message a bien été envoyé.')
      } catch {
        onError()
        this.$toasted.error('Erreur lors de l\'envoi. Veuillez réessayer.')
      }
    }
  }
}
</script>
